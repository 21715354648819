let browserEnv = {};

// Allow configuration of environment variables through browser environment
if (window !== undefined && window.BROWSER_ENV !== undefined) {
    browserEnv = window.BROWSER_ENV;
}
export const TOKEN_ENV = browserEnv.tokenEnv;
export const defaultWebAppEnabled = browserEnv.defaultWebAppEnabled;
export const hotModule = browserEnv.hotModule;
export const webAppUrl = browserEnv.webAppUrl;
export const WEB_APP_ENV = browserEnv.webAppEnv || TOKEN_ENV;
export const customDestination = browserEnv.customDestination;
export const defaults = browserEnv.defaults;
export const customSource = browserEnv.customSource;
export const destinations = browserEnv.destinations;
export const destinationDefaults = browserEnv.destinationDefaults || {};
export const memberInfo = browserEnv.memberInfo;
export const merchantUrl = browserEnv.merchantUrl;
export const currencySymbols = browserEnv.currencySymbols;
export const productCatalog = browserEnv.productCatalog;
export const TYPE_ONE = 'type1';
export const TYPE_TWO = 'type2';
export const TYPE_TWO_CALLBACK = 'type2_callback';
export const CLIENTS_URL_MAPPINGS = browserEnv.clientBaseUrl;
export const CLIENTS_LOGO_MAPPINGS = browserEnv.logo;
export const TOKEN_API_URL = TOKEN_ENV == 'local'
    ? 'http://localhost:8000'
    : (TOKEN_ENV !== 'prd' ? `https://api.${TOKEN_ENV}.token.io` : 'https://api.token.io');
export const schemes = {
    'sepa': {
        currencies: ['EUR', 'PLN'],
        accountFields: {
            'iban': {
                isOptional: false,
            },
            'bic': {
                isOptional: true,
            },
        },
    },
    'fasterPayments': {
        currencies: ['GBP'],
        accountFields: {
            'sortCode': {
                isOptional: false,
            },
            'accountNumber': {
                isOptional: false,
            },
        },
        validation: {
            'sortCode': {
                description: '(6 digit)',
                pattern: '[0-9]{6}',
            },
            'accountNumber': {
                description: '(8 digit)',
                pattern: '[0-9]{8}',
            },
        },
    },
    'elixir': {
        currencies: ['PLN'],
        accountFields: {
            'accountNumber': {
                isOptional: false,
            },
        },
    },
    'swift': {
        currencies: ['EUR', 'GBP'],
        accountFields: {
            'account': {
                isOptional: false,
            },
            'bic': {
                isOptional: false,
            },
        },
    },
    'sepaInstant': {
        currencies: ['EUR'],
        accountFields: {
            'iban': {
                isOptional: false,
            },
            'bic': {
                isOptional: true,
            },
        },
    },
    'euDomesticNonEuro': {
        currencies: ['SEK', 'HUF', 'NOK', 'BGN', 'DKK', 'CZK'],
        accountFields: {
            'iban': {
                isOptional: false,
            },
            'bic': {
                isOptional: false,
            },
        },
    },
};

export const sourceScheme = {
    'iban': {
        accountFields: {
            'iban': {
                isOptional: false,
            },
            'bic': {
                isOptional: true,
            },
        },
    },
    'domestic': {
        accountFields: {
            'accountNumber': {
                isOptional: false,
            },
            'bankCode': {
                isOptional: false,
            },
        },
    },
};

export const displayNames = {
    'sepa': 'SEPA',
    'fasterPayments': 'FasterPayments',
    'elixir': 'Elixir',
    'iban': 'IBAN',
    'bic': 'BIC',
    'sortCode': 'Sort Code',
    'accountNumber': 'Account Number',
    'swift': 'SWIFT',
    'account': 'IBAN',
    'country': 'Country',
    'bankCode': 'Bank Code',
    'domestic': 'Domestic',
    'bankId': 'Bank ID',
    'sepaInstant': 'Sepa Instant',
    'euDomesticNonEuro': 'EuDomesticNonEuro',
};

// Update for breaking changes that require flushing localStorage
export const version = '3.1';

export const frequencies = [
    {
        key: 'Daily',
        code: 'DAIL',
    },
    {
        key: 'Weekly',
        code: 'WEEK',
    },
    {
        key: 'Biweekly',
        code: 'TOWK',
    },
    {
        key: 'Monthly',
        code: 'MNTH',
    },
    {
        key: 'Bimonthly',
        code: 'TOMN',
    },
    {
        key: 'Quarterly',
        code: 'QUTR',
    },
    {
        key: 'Semi annually',
        code: 'SEMI',
    },
    {
        key: 'Anually',
        code: 'YEAR',
    },
];

export const PERIODIC_LIMITS = {
    DAILY: 'DAY',
    WEEKLY: 'WEEK',
    MONTHLY: 'MONTH',
    YEARLY: 'YEAR',
};

export const accountSchemes = [
    {
        key: 'Iban',
        code: 'iban',
    },
    {
        key: 'Account Number',
        code: 'domestic',
    },
];

export const regexForFrequency = {
    'DAIL': ['^(EvryDay)$', '^(EvryWorkgDay)$', '^(IntrvlDay:((0[2-9])|([1-2][0-9])|3[0-1]))$'],
    'WEEK': ['^(IntrvlWkDay:0[1-9]:0[1-7])$'],
    'MNTH': ['^(WkInMnthDay:0[1-5]:0[1-7])$', '^(IntrvlMnthDay:(0[1-6]|12|24):(-0[1-5]|0[1-9]|[12][0-9]|3[01]))$'],
    'QUTR': ['^(QtrDay:ENGLISH)$', '^(QtrDay:SCOTTISH)$', '^(QtrDay:RECEIVED)$', '^(IntrvlMnthDay:03:(-0[1-5]|0[1-9]|[12][0-9]|3[01]))$'],
    'SEMI': ['^(IntrvlMnthDay:06:(-0[1-5]|0[1-9]|[12][0-9]|3[01]))$'],
    'YEAR': ['^(IntrvlMnthDay:12:(-0[1-5]|0[1-9]|[12][0-9]|3[01]))$'],
};

export const DEMO_PORTAL_CLEINT_CONFIG = {
    'bnpp': {
        'options': [
            {
                key: 'Pay with Instanea',
                code: 'payByBank',
            },
            {
                key: 'Standing Order',
                code: 'standingOrder',
            },
            {
                key: 'Single Future Dated',
                code: 'futureDated',
            },
            {
                key: 'Confirm Funds',
                code: 'confirmFunds',
            },
            {
                key: 'Bulk Transfer',
                code: 'bulkTransfer',
            },
        ],
    },
    'srtpdemo': {
        'options': [
            {
                key: 'Pay with Instanea',
                code: 'payByBank',
            },
            {
                key: 'Standing Order',
                code: 'standingOrder',
            },
            {
                key: 'Single Future Dated',
                code: 'futureDated',
            },
            {
                key: 'Confirm Funds',
                code: 'confirmFunds',
            },
            {
                key: 'Bulk Transfer',
                code: 'bulkTransfer',
            },
        ],
    },
    'southside': {
        'options': [
            {
                key: 'PAY BY BANK',
                code: 'payByBank',
            },
            {
                key: 'STANDING ORDER',
                code: 'standingOrder',
            },
            {
                key: 'SINGLE FUTURE DATED',
                code: 'futureDated',
            },
            {
                key: 'CONFIRM FUNDS',
                code: 'confirmFunds',
            },
            {
                key: 'BULK TRANSFER',
                code: 'bulkTransfer',
            },
        ],
    },
};

export const VRP_AMOUNT = {
    CUSTOM: 'CUSTOM',
    STATEMENT_BALANCE: 'STATEMENT_BALANCE',
    MINIMUM_AMOUNT_DUE: 'MINIMUM_AMOUNT_DUE',
};

export const LOCAL_INSTRUMENTS = {
    IBAN: 'SEPA',
    DOMESTIC: 'FASTER_PAYMENTS',
};

export const SERVICE_PROVIDERS = {
    NETFLIX: {
        issuingBank: 'HSBC',
        accountNickName: 'Netflix',
    },
    'AMAZON PRIME': {
        issuingBank: 'HSBC',
        accountNickName: 'Amazon Prime',
    },
};

export const VRP_TYPES = ['SWEEPING', 'NON_SWEEPING'];

export const VRP_TYPE_SWEEPING = 'SWEEPING';
export const VRP_TYPE_NON_SWEEPING = 'NON_SWEEPING';

export const VRP_PAYMENT_STATUS = {
    INVALID_STATUS: 'INVALID_STATUS',
    INITIATION_PENDING: 'INITIATION_PENDING',
    INITIATION_PROCESSING: 'INITIATION_PROCESSING',
    INITIATION_COMPLETED: 'INITIATION_COMPLETED',
    INITIATION_REJECTED: 'INITIATION_REJECTED',
    INITIATION_REJECTED_INSUFFICIENT_FUND: 'INITIATION_REJECTED_INSUFFICIENT_FUND',
    INITIATION_FAILED: 'INITIATION_FAILED',
};

export const PAY_BY_BANK = 'payByBank';
export const STANDING_ORDER = 'standingOrder';
export const FUTURE_DATED = 'futureDated';
export const CONFIRM_FUNDS = 'confirmFunds';
export const BULK_TRANSFER = 'bulkTransfer';

export const APP_SDK_ENVS = {
    local: 'local',
    dev: 'development',
    stg: 'staging',
    sandbox: 'sandbox',
    perf: 'performance',
    beta: 'beta',
    prd: 'production',
};

export const APP_SDK_ENV = APP_SDK_ENVS[TOKEN_ENV];
